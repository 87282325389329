import { Component, Output, EventEmitter, OnDestroy, OnInit, AfterViewInit, ChangeDetectorRef, Inject, Renderer2, ViewChild, ElementRef, ViewChildren, QueryList, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../services/layout.service';
import { delay, map, filter } from 'rxjs/operators';
import { Observable, of, Subscription } from 'rxjs';
import { ConfigService } from '../services/config.service';
import { FormControl } from '@angular/forms';
import { LISTITEMS } from '../data/template-search';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Documento } from 'app/oDres/modelos/Empresa/documento';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { AuthService } from '../auth/auth.service';
import { ModalDismissReasons, NgbCalendar, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorageService, SessionStorageService } from 'angular-web-storage';
import swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
import { Usuario } from 'app/oDres/modelos/Empresa/usuarios';
import { FileUploader } from 'ng2-file-upload';
import { Notificacion } from 'app/oDres/modelos/Empresa/notificacion';
import { NotificacionService } from '../services/notificaciones.service';
import { Empresa } from 'app/oDres/modelos/Empresa/empresa';
import { Title } from '@angular/platform-browser';
import { JsonResponse } from 'app/oDres/modelos/Datos/jsonResponse';
const URL = 'https://evening-anchorage-3159.herokuapp.com/api/';
const now = new Date();

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  currentLang = 'es';
  selectedLanguageText = 'Spanish';
  selectedLanguageFlag = './assets/img/flags/es.png';
  toggleClass = 'far fa-square';
  placement = 'bottom-right';
  logoUrl = 'assets/img/logo.png';
  menuPosition = 'Side';
  isSmallScreen = false;
  protected innerWidth: any;
  searchOpenClass = '';
  transparentBGClass = '';
  hideSidebar = true;
  public isCollapsed = true;
  layoutSub: Subscription;
  configSub: Subscription;

  mostrarNotificaciones = false;

  @ViewChild('search') searchElement: ElementRef;
  @ViewChildren('searchResults') searchResults: QueryList<any>;

  @Output()
  toggleHideSidebar = new EventEmitter<Object>();

  @Output()
  seachTextEmpty = new EventEmitter<boolean>();

  listItems = [];
  control = new FormControl();

  public config: any = {};

  closeResult: string;

  uploader: FileUploader = new FileUploader({
    url: URL,
    isHTML5: true
  });
  hasBaseDropZoneOver = false;
  hasAnotherDropZoneOver = false;

  croppedImage: any = '';

  showCropper = false;

  imageChangedEvent: any = '';

  fileName = '';

  documentoSubir: Documento = {} as Documento;

  apretoGuardarContrasena = false;
  contrasenaActual = null;
  contrasenaNueva = null;
  contrasenaNuevaConfirmar = null;

  errorContrasena = false;
  errorContrasenaNueva = false;
  errorContrasenaNuevaCoincidencia = false;
  contrasenaCoincide = true;

  canvasRotation = 0;
  transform: ImageTransform = {};
  rotation = 0;
  scale = 1;

  notificacionSeleccionada = {};

  empresaSeleccionado: Empresa = {} as Empresa;
  listaEmpresa: Empresa[] = [];
  filteredlistaEmpresa: Observable<Empresa[]>;

  today = now.toDateString();

  constructor(public authService: AuthService,
    public translate: TranslateService,
    private layoutService: LayoutService,
    private router: Router,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private notificacionService: NotificacionService,
    public local: LocalStorageService,
    public session: SessionStorageService,
    public titleService: Title,
    private activatedRoute: ActivatedRoute) {

    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|es|pt|de/) ? browserLang : 'en');
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;

    this.layoutSub = layoutService.toggleSidebar$.subscribe(
      isShow => {
        this.hideSidebar = !isShow;
      });

  }

  ngOnInit() {
    this.listItems = LISTITEMS;

    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    } else {
      this.isSmallScreen = false;
    }

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route: ActivatedRoute = this.router.routerState.root;
          let routeTitle = '';
          while (route!.firstChild) {
            route = route.firstChild;
          }
          if (route.snapshot.data['title']) {
            routeTitle = route!.snapshot.data['title'];
          }
          return routeTitle;
        })
      )
      .subscribe((title: string) => {
        if (title) {
          this.titleService.setTitle(`${title}`);
        }
      });
  }

  ngAfterViewInit() {

    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();

    })

  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }
  }

  marcarTodasComoLeidas() {
    const opciones: SweetAlertOptions = {
      icon: 'question',
      title: 'Marcar todas como leidas',
      text: '¿Estas seguro de desea marcar todas las notificaciones como leidas?',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonText: 'Sí',
      confirmButtonColor: '#30d65f',
      cancelButtonColor: '#d64130',
      allowOutsideClick: false,
      allowEscapeKey: false
    } as SweetAlertOptions;

    swal.fire(opciones).then((resultado) => {
      if (resultado.value) {

        this.spinner.show();

        this.authService.usuarioConectado.ListaNotificaciones.forEach(notificacion => {

          if (notificacion.Abierta) {

            notificacion.Abierta = false;

            this.notificacionService.actualizarNotificacion({ IdEmpresa: this.authService.usuarioConectado.Empresa.IdEmpresa, IdNotificacion: notificacion.IdNotificacion, Abierta: false } as Notificacion).subscribe(respuestaNotificacion => {

              //console.log(respuestaNotificacion);

              if (respuestaNotificacion != null && respuestaNotificacion.code == 200) {
                this.local.set('UsuarioCompleto_' + this.authService.usuarioConectado.Empresa.IdEmpresa, JSON.stringify(this.authService.usuarioConectado));
              }

            });

          }

        });

        this.authService.usuarioConectado.NumeroNotificacionesSinVer = 0;

        this.spinner.hide();

      }
    });
  }

  eliminarNotificacion(notificacion) {

    const opciones: SweetAlertOptions = {
      icon: 'question',
      title: 'Eliminar notificación',
      text: '¿Estas seguro de eliminar esta notificación, ' + notificacion.Titulo + '?',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonText: 'Sí',
      confirmButtonColor: '#30d65f',
      cancelButtonColor: '#d64130',
      allowOutsideClick: false,
      allowEscapeKey: false
    } as SweetAlertOptions;

    swal.fire(opciones).then((resultado) => {
      if (resultado.value) {

        this.spinner.show();

        this.notificacionService.eliminarNotificacion(notificacion.IdNotificacion).subscribe(respuestaNotificacion => {

          //console.log(respuestaNotificacion);

          if (respuestaNotificacion != null && respuestaNotificacion.code == 200) {

            this.notificacionService.obtenerNotificacionesCompletas({
              IdEmpresa: this.authService.usuarioConectado.Empresa.IdEmpresa,
              IdUsuarioDirigidoA: this.authService.usuarioConectado.Usuario.IdUsuario
            } as Notificacion).subscribe(respuestaNotificacion => {

              //console.log(respuestaNotificacion);

              if (respuestaNotificacion != null && respuestaNotificacion.code == 200) {
                this.authService.usuarioConectado.ListaNotificaciones = respuestaNotificacion.model;

                this.local.set('UsuarioCompleto_' + this.authService.usuarioConectado.Empresa.IdEmpresa, JSON.stringify(this.authService.usuarioConectado));

                const opciones: SweetAlertOptions = {
                  icon: 'success',
                  title: 'Notificación eliminada',
                  text: 'La notificación ha sido eliminada',
                  type: 'success',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                } as SweetAlertOptions;

                this.spinner.hide();

                swal.fire(opciones);

              } else {

                const opciones: SweetAlertOptions = {
                  icon: 'error',
                  title: 'Error',
                  text: respuestaNotificacion.message,
                  type: 'error',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                } as SweetAlertOptions;

                this.spinner.hide();

                swal.fire(opciones);

              }

            });

          } else {

            const opciones: SweetAlertOptions = {
              icon: 'error',
              title: 'Error',
              text: respuestaNotificacion.message,
              type: 'error',
              allowOutsideClick: false,
              allowEscapeKey: false
            } as SweetAlertOptions;

            this.spinner.hide();

            swal.fire(opciones);

          }

        });

      }
    });

  }

  abrirNotificacion(notificacion, content) {

    if (notificacion.Abierta) {
      notificacion.Abierta = false;

      const indexNotificacion = this.authService.usuarioConectado.ListaNotificaciones.findIndex(n => n.IdNotificacion == notificacion.IdNotificacion);

      const numeroAbiertas = this.authService.usuarioConectado.ListaNotificaciones.filter(n => n.Abierta == true).length;

      this.authService.usuarioConectado.ListaNotificaciones[indexNotificacion] = notificacion;

      this.authService.usuarioConectado.NumeroNotificacionesSinVer = numeroAbiertas;

      this.local.set('UsuarioCompleto_' + this.authService.usuarioConectado.Empresa.IdEmpresa, JSON.stringify(this.authService.usuarioConectado));

      // aqui va al servidor a cerrarla sin spinner ya que no importa cuanto tarde en cerrarla

      this.notificacionService.actualizarNotificacion({ IdEmpresa: this.authService.usuarioConectado.Empresa.IdEmpresa, IdNotificacion: notificacion.IdNotificacion, Abierta: false } as Notificacion).subscribe(respuestaNotificacion => {

        //console.log(respuestaNotificacion);

        if (respuestaNotificacion != null && respuestaNotificacion.code == 200) {

        }

      });

    }

    this.notificacionSeleccionada = notificacion;

    this.modalService.open(content, { backdrop: 'static', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      //console.log(this.closeResult);
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      //console.log(this.closeResult);
    });

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    } else {
      this.isSmallScreen = false;
    }
  }

  loadLayout() {

    if (this.config.layout.menuPosition && this.config.layout.menuPosition.toString().trim() != '') {
      this.menuPosition = this.config.layout.menuPosition;
    }

    if (this.config.layout.variant === 'Light') {
      this.logoUrl = 'assets/img/logo-dark.png';
    } else {
      this.logoUrl = 'assets/img/logo.png';
    }

    if (this.config.layout.variant === 'Transparent') {
      this.transparentBGClass = this.config.layout.sidebar.backgroundColor;
    } else {
      this.transparentBGClass = '';
    }

  }

  onSearchKey(event: any) {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.add('first-active-item');
    }

    if (event.target.value === '') {
      this.seachTextEmpty.emit(true);
    } else {
      this.seachTextEmpty.emit(false);
    }
  }

  removeActiveClass() {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.remove('first-active-item');
    }
  }

  onEscEvent() {
    this.control.setValue('');
    this.searchOpenClass = '';
    this.seachTextEmpty.emit(true);
  }

  onEnter() {
    if (this.searchResults && this.searchResults.length > 0) {
      const url = this.searchResults.first.url;
      if (url && url != '') {
        this.control.setValue('');
        this.searchOpenClass = '';
        this.router.navigate([url]);
        this.seachTextEmpty.emit(true);
      }
    }
  }

  redirectTo(value) {
    this.router.navigate([value]);
    this.seachTextEmpty.emit(true);
  }


  ChangeLanguage(language: string) {
    this.translate.use(language);

    if (language === 'en') {
      this.selectedLanguageText = 'English';
      this.selectedLanguageFlag = './assets/img/flags/us.png';
    } else if (language === 'es') {
      this.selectedLanguageText = 'Spanish';
      this.selectedLanguageFlag = './assets/img/flags/es.png';
    } else if (language === 'pt') {
      this.selectedLanguageText = 'Portuguese';
      this.selectedLanguageFlag = './assets/img/flags/pt.png';
    } else if (language === 'de') {
      this.selectedLanguageText = 'German';
      this.selectedLanguageFlag = './assets/img/flags/de.png';
    }
  }

  ToggleClass() {
    if (this.toggleClass === 'far fa-square') {
      this.toggleClass = 'fas fa-compress';
    } else {
      this.toggleClass = 'far fa-square';
    }
  }

  toggleSearchOpenClass(display) {
    this.control.setValue('');
    if (display) {
      this.searchOpenClass = 'open';
      setTimeout(() => {
        this.searchElement.nativeElement.focus();
      }, 0);
    } else {
      this.searchOpenClass = '';
    }
    this.seachTextEmpty.emit(true);
  }

  toggleNotificationSidebar() {
    this.layoutService.toggleNotificationSidebar(true);
  }

  toggleSidebar() {
    this.layoutService.toggleSidebarSmallScreen(this.hideSidebar);
  }

  cerrarSesion() {
    this.local.clear();
    this.authService.usuarioConectado = null;
    this.authService.IdEmpresaSeleccionada = 0;
    this.configService.applyTemplateConfigChange({
      layout: {
        variant: 'Light', // options:  Dark, Light & Transparent
        menuPosition: 'Side', // Options: ltr, rtl
        customizer: {
          hidden: true // options: true, false
        },
        navbar: {
          type: 'Fixed'
        },
        sidebar: {
          collapsed: true, // options: true, false
          size: 'sidebar-md', // Options: 'sidebar-lg', 'sidebar-md', 'sidebar-sm'
          backgroundColor: 'man-of-steel', // Options: 'black', 'pomegranate', 'king-yna', 'ibiza-sunset', 'flickr', 'purple-bliss', 'man-of-steel', 'purple-love'

          /* If you want transparent layout add any of the following mentioned classes to backgroundColor of sidebar option :
            bg-glass-1, bg-glass-2, bg-glass-3, bg-glass-4, bg-hibiscus, bg-purple-pizzaz, bg-blue-lagoon, bg-electric-viloet, bg-protage, bg-tundora
          */
          backgroundImage: true, // Options: true, false | Set true to show background image
          backgroundImageURL: 'https://grupoodresdiag.blob.core.windows.net/odes191231/010.jpg'
        }
      }
    });
    this.router.navigate(['/inicio/login']);
  }

  editarInformacion(content) {

    this.modalService.open(content, { backdrop: 'static', windowClass: 'verInformacionUsuario', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      //console.log(this.closeResult);
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      //console.log(this.closeResult);
    });

  }

  misInformacion() {
    this.router.navigate(['/generales/UsuarioProfile']);
  }

  misTareas() {
    this.router.navigate(['/dashboards/dashboard-administrador-tareas']);
  }

  adminTareas() {
    this.router.navigate(['/generales/AdminTareas']);
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  fileOverBase(e: any): void {
    // this.hasBaseDropZoneOver = e;
    this.imageChangedEvent = event;
  }

  fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  fileChangeEvent(event: any): void {
    this.fileName = event.target.files[0].name;
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    this.showCropper = true;
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  cancelCropping() {
    this.imageChangedEvent = null;
    this.showCropper = false;
  }

  editarAvatar(content) {

    this.modalService.open(content, { backdrop: 'static', windowClass: 'editarAvatar', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      //console.log(this.closeResult);
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      //console.log(this.closeResult);
    });

  }

  guardarImagen() {

    this.spinner.show();

    this.documentoSubir = {
      nombreArchivo: 'imagenesavatar/' + this.authService.usuarioConectado.Usuario.IdUsuario + '/' + this.fileName,
      contenedor: this.authService.usuarioConectado.Empresa.Identificador.toLocaleLowerCase().trim(),
      esImagen: true,
      archivo: this.croppedImage.replace('data:image/png;base64,', ''),
      IdUsuario: this.authService.usuarioConectado.Usuario.IdUsuario
    };

    this.configService.guardarAvatar(this.documentoSubir).subscribe((datos) => {

      //console.log(datos);

      if (datos != null && datos.code === 200) {

        this.imageChangedEvent = null;
        this.showCropper = false;

        this.authService.usuarioConectado.Usuario.Urlimagen = datos.model;

        this.spinner.hide();

        this.modalService.dismissAll();

      } else {

        const opciones: SweetAlertOptions = {
          icon: 'error',
          title: 'Error',
          text: datos.message,
          type: 'error',
          allowOutsideClick: false,
          allowEscapeKey: false
        } as SweetAlertOptions;

        this.spinner.hide();

        swal.fire(opciones);

      }

    });

  }

  cambiarContrasena() {

    this.apretoGuardarContrasena = true;

    this.confirmarContrasena();

    if (!this.errorContrasena
      && !this.errorContrasenaNueva
      && !this.errorContrasenaNuevaCoincidencia
      && this.contrasenaCoincide) {

      this.spinner.show();

      // Primero revisar que la contraseña anterior si sea igual a la que tiene actualmente
      this.configService.obtenerUsuario({ IdUsuario: this.authService.usuarioConectado.Usuario.IdUsuario } as Usuario).subscribe(usuarioRespuesta => {

        //console.log(usuarioRespuesta);

        if (usuarioRespuesta != null && usuarioRespuesta.code == 200) {

          this.configService.actualizarContrasenaUsuario({
            IdUsuario: this.authService.usuarioConectado.Usuario.IdUsuario,
            contrasena: this.contrasenaActual,
            contrasenaNueva: this.contrasenaNueva
          } as Usuario).subscribe(usuarioActualizarRespuesta => {

            //console.log(usuarioActualizarRespuesta);

            if (usuarioActualizarRespuesta != null && usuarioActualizarRespuesta.code == 200) {

              this.spinner.hide();

              this.contrasenaActual = null;
              this.contrasenaNueva = null;
              this.contrasenaNuevaConfirmar = null;
              this.apretoGuardarContrasena = false;

              this.modalService.dismissAll();

            } else {

              const opciones: SweetAlertOptions = {
                icon: 'error',
                title: 'Error',
                text: usuarioActualizarRespuesta.message,
                type: 'error',
                allowOutsideClick: false,
                allowEscapeKey: false
              } as SweetAlertOptions;

              this.spinner.hide();

              swal.fire(opciones);

            }

          });

        } else {

          const opciones: SweetAlertOptions = {
            icon: 'error',
            title: 'Error',
            text: usuarioRespuesta.message,
            type: 'error',
            allowOutsideClick: false,
            allowEscapeKey: false
          } as SweetAlertOptions;

          this.spinner.hide();

          swal.fire(opciones);

        }

      });

    }

  }

  cerrarModalUsuario() {
    this.contrasenaActual = null;
    this.contrasenaNueva = null;
    this.contrasenaNuevaConfirmar = null;
    this.apretoGuardarContrasena = false;
    this.modalService.dismissAll();
  }

  confirmarContrasena() {

    //console.log('confirmarContrasena');

    this.errorContrasena = false;
    this.errorContrasenaNuevaCoincidencia = false;

    if (this.apretoGuardarContrasena) {

      if ((this.contrasenaActual != null
        && this.contrasenaActual != undefined
        && this.contrasenaActual.trim() != '')) {

        if ((this.contrasenaNueva != null
          && this.contrasenaNueva != undefined
          && this.contrasenaNueva.trim() != '')) {

          if ((this.contrasenaNuevaConfirmar != null
            && this.contrasenaNuevaConfirmar != undefined
            && this.contrasenaNuevaConfirmar.trim() != '')) {

            this.errorContrasena = false;
            this.errorContrasenaNueva = false;
            this.errorContrasenaNuevaCoincidencia = false;

            if (this.contrasenaNueva.trim()
              == this.contrasenaNuevaConfirmar.trim()) {
              this.contrasenaCoincide = true;
            } else {
              this.contrasenaCoincide = false;
            }

          } else {
            this.errorContrasenaNueva = false;
            this.errorContrasenaNuevaCoincidencia = true;
          }

        } else {
          this.errorContrasena = false;
          this.errorContrasenaNueva = true;

          if ((this.contrasenaNuevaConfirmar != null
            && this.contrasenaNuevaConfirmar != undefined
            && this.contrasenaNuevaConfirmar.trim() != '')) {

            this.errorContrasenaNuevaCoincidencia = false;

          } else {

            this.errorContrasenaNuevaCoincidencia = true;
          }

        }

      } else {

        if ((this.contrasenaNueva != null
          && this.contrasenaNueva != undefined
          && this.contrasenaNueva.trim() != '')) {

          this.errorContrasena = true;
          this.errorContrasenaNueva = false;

          if ((this.contrasenaNuevaConfirmar != null
            && this.contrasenaNuevaConfirmar != undefined
            && this.contrasenaNuevaConfirmar.trim() != '')) {

            this.errorContrasena = true;
            this.errorContrasenaNuevaCoincidencia = false;

          } else {

            this.errorContrasenaNuevaCoincidencia = true;

          }
        } else {

          if ((this.contrasenaNueva != null
            && this.contrasenaNueva != undefined
            && this.contrasenaNueva.trim() != '')) {

            this.errorContrasena = true;
            this.errorContrasenaNueva = true;
            this.errorContrasenaNuevaCoincidencia = false;

          } else {

            this.errorContrasena = false;
            this.errorContrasenaNueva = false;
            this.errorContrasenaNuevaCoincidencia = false;

          }

        }



      }

    }

  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  updateRotation() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation
    };
  }

  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  getFile(event: any) {
    //console.log('file: ', event.target.files[0]);

    this.fileName = event.target.files[0].name;
    this.imageChangedEvent = event;
  }

  revisarRotation() {
    //console.log(this.rotation);

    if (this.rotation == undefined || this.rotation == null) {
      this.rotation = 0;
    }
  }

  zoomChange() {
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  revisarZoom() {
    //console.log(this.scale);

    if (this.scale == undefined || this.scale == null) {
      this.scale = 1;
    }
  }

  cambiarEmpresa(component) {

    this.listaEmpresa = [];

    this.configService.inicializarCatalogo(this.listaEmpresa, (e: Empresa) => this.empresaSeleccionado = e, 'IdEmpresa', 'Nombre', 'Seleccione Empresa...');
    this.authService.listaUsuarioCompleto.forEach(d => {

      if (this.authService.usuarioConectado.Empresa.IdEmpresa != d.Empresa.IdEmpresa) {

        this.listaEmpresa.push(d.Empresa);

      }

    });

    this.filteredlistaEmpresa = this._filteredlistaEmpresa();

    this.modalService.open(component, { backdrop: 'static', centered: true });
  }

  _filteredlistaEmpresa(term: string = null): Observable<Empresa[]> {

    if (term) {
      this.listaEmpresa = this.listaEmpresa.filter(x => x.Nombre.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1);
    }
    return of(this.listaEmpresa).pipe(delay(500));
  }

  seleccionarEmpresa() {

    //console.log(this.authService.IdEmpresaSeleccionada);

    if (this.authService.IdEmpresaSeleccionada != 0) {

      // Aqui vamos por la configuracion de la empresa de ese usuario
      const indexEmpresa = this.authService.listaUsuarioCompleto.findIndex(e => e.Empresa.IdEmpresa == this.authService.IdEmpresaSeleccionada);      

      if (this.authService.listaUsuarioCompleto[indexEmpresa].Empresa.Activo) {

        this.spinner.show();

        this.local.clear();
        this.authService.usuarioConectado = null;
        this.configService.applyTemplateConfigChange({
          layout: {
            variant: 'Light', // options:  Dark, Light & Transparent
            menuPosition: 'Side', // Options: ltr, rtl
            customizer: {
              hidden: true // options: true, false
            },
            navbar: {
              type: 'Fixed'
            },
            sidebar: {
              collapsed: true, // options: true, false
              size: 'sidebar-md', // Options: 'sidebar-lg', 'sidebar-md', 'sidebar-sm'
              backgroundColor: 'man-of-steel', // Options: 'black', 'pomegranate', 'king-yna', 'ibiza-sunset', 'flickr', 'purple-bliss', 'man-of-steel', 'purple-love'

              /* If you want transparent layout add any of the following mentioned classes to backgroundColor of sidebar option :
                bg-glass-1, bg-glass-2, bg-glass-3, bg-glass-4, bg-hibiscus, bg-purple-pizzaz, bg-blue-lagoon, bg-electric-viloet, bg-protage, bg-tundora
              */
              backgroundImage: true, // Options: true, false | Set true to show background image
              backgroundImageURL: 'https://grupoodresdiag.blob.core.windows.net/odes191231/010.jpg'
            }
          }
        });

        this.modalService.dismissAll();

        this.router.navigate(['/inicio/cambio-empresa']);

      } else {

        // Aqui mandar el modal de compra de licencias

        // Aqui si no esta activa la empresa hay que ponerle la pantalla de comprar licencia

        const opciones: SweetAlertOptions = {
          icon: 'warning',
          title: 'Error',
          text: 'Empresa no activa',
          type: 'warning',
          allowOutsideClick: false,
          allowEscapeKey: false
        } as SweetAlertOptions;

        swal.fire(opciones);

        this.spinner.hide();

      }

    } else {

      const opciones: SweetAlertOptions = {
        icon: 'warning',
        title: 'Seleccion empresa',
        text: 'Debes seleccionar una empresa',
        type: 'warning',
        allowOutsideClick: false,
        allowEscapeKey: false
      } as SweetAlertOptions;

      swal.fire(opciones);

    }

  }

  cancelarCambioEmpresa() {
    this.modalService.dismissAll();
  }
}

import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'angular-web-storage';
import { AuthService } from '../auth/auth.service';
import { ConfigService } from '../services/config.service';

@Injectable({
    providedIn: 'root'
})
export class InactivityService {
    private timeoutId: any;
    private readonly timeout: number = 15 * 60 * 1000; // 15 minutos

    constructor(private router: Router, private ngZone: NgZone, 
        public local: LocalStorageService, public authService: AuthService, private configService: ConfigService) {
        this.startTimer();
        this.setupListeners();
    }

    private startTimer() {
        this.ngZone.runOutsideAngular(() => {
            this.timeoutId = setTimeout(() => this.logout(), this.timeout);
        });
    }

    private resetTimer() {
        clearTimeout(this.timeoutId);
        this.startTimer();
    }

    private setupListeners() {
        document.addEventListener('mousemove', () => this.resetTimer());
        document.addEventListener('keydown', () => this.resetTimer());
        document.addEventListener('click', () => this.resetTimer());
    }

    private logout() {
        this.ngZone.run(() => {
            // Aquí puedes añadir la lógica para cerrar sesión, por ejemplo:
            // this.authService.logout();
            //this.router.navigate(['/login']);

            this.local.clear();
            this.authService.usuarioConectado = null;
            this.authService.IdEmpresaSeleccionada = 0;
            this.configService.applyTemplateConfigChange({
                layout: {
                    variant: 'Light', // options:  Dark, Light & Transparent
                    menuPosition: 'Side', // Options: ltr, rtl
                    customizer: {
                        hidden: true // options: true, false
                    },
                    navbar: {
                        type: 'Fixed'
                    },
                    sidebar: {
                        collapsed: true, // options: true, false
                        size: 'sidebar-md', // Options: 'sidebar-lg', 'sidebar-md', 'sidebar-sm'
                        backgroundColor: 'man-of-steel', // Options: 'black', 'pomegranate', 'king-yna', 'ibiza-sunset', 'flickr', 'purple-bliss', 'man-of-steel', 'purple-love'

                        /* If you want transparent layout add any of the following mentioned classes to backgroundColor of sidebar option :
                          bg-glass-1, bg-glass-2, bg-glass-3, bg-glass-4, bg-hibiscus, bg-purple-pizzaz, bg-blue-lagoon, bg-electric-viloet, bg-protage, bg-tundora
                        */
                        backgroundImage: true, // Options: true, false | Set true to show background image
                        backgroundImageURL: 'https://grupoodresdiag.blob.core.windows.net/odes191231/010.jpg'
                    }
                }
            });
            this.router.navigate(['/inicio/login']);
        });
    }
}